<template>
  <!-- 实习报名 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习报名</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="h-contain">
        <div class="h-title2">{{ item_desc.plan_name }}</div>
        <div class="h-title3">
          {{ item_desc.user_name || item_desc.name }}&nbsp;&nbsp;&nbsp;&nbsp; {{ item_desc.student_sn }}
          <div class="h-title3_1">
            {{ item_desc.college_name }} / {{ item_desc.major_name }} /
            {{ item_desc.class_name }}
          </div>
        </div>
      </div>
      <div class="h2-contain">
        <div class="bodycontain">
          <el-form class="elform" ref="formone" :rules="rules" :model="formone" label-position="top">
            <p class="p-title">实习信息</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :span="8">
                <el-form-item label="实习单位" class="elformitem" prop="company">
                  <el-input v-model="formone.company" placeholder="请输入实习单位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实习岗位" class="elformitem" prop="job">
                  <el-input v-model="formone.job" placeholder="请输入实习岗位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实习时间" class="elformitem" prop="timearr">
                  <el-date-picker v-model="formone.timearr" type="daterange" value-format="timestamp" @change="selectTime" start-placeholder="实习开始时间"
                    end-placeholder="实习结束时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实习方式" class="elformitem" prop="mode">
                  <el-select class="elformitem" v-model="formone.mode" placeholder="请选择">
                    <el-option v-for="(item, index) in optiontwo" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实习地点" class="elformitem3" prop="address">
                  <el-input v-model="formone.address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="签到地点" class="elformitem3" prop="sign_in_address">
                  <div class="map">
                    <el-input v-model="formone.sign_in_address" placeholder="请输入"></el-input>
                    <div class="elimage" @click="checkbt">
                      <el-image style="width: 30px; height: 30px" :src="mapicon"></el-image>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实习待遇" class="elformitem" prop="salary">
                  <el-input v-model="formone.salary" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实习单位接收函" class="elformitem">
                  <div class="file-select-contain">
                    <div class="file-title">{{!formone.receiving_letter? '未选择文件' : '单位接收函'}}</div>
                    <UploadFiles :showFile="false" :multiple="false" :module="'practice_plan_freely_apply_info,url'" :cloudType="'practicePlanFreelyApplyInfo/url'"  @setUrl="getUrl1"/>
                    <div class="file-selecttip" @click="download(1)">下载模板</div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="安全承诺书" class="elformitem">
                  <div class="file-select-contain">
                    <div class="file-title">{{!formone.safety_letter ? '未选择文件' : '安全承诺书'}}</div>
                    <UploadFiles :showFile="false" :multiple="false" :module="'practice_plan_freely_apply_info,url'" :cloudType="'practicePlanFreelyApplyInfo/url'" @setUrl="getUrl2"/>
                    <div class="file-selecttip" @click="download(2)">下载模板</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="p-title">实习单位信息</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :span="8">
                <el-form-item label="单位联系人" class="elformitem" prop="company_user">
                  <el-input v-model="formone.company_user" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单位电话" class="elformitem" prop="company_tel">
                  <el-input v-model="formone.company_tel" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单位地址" class="elformitem" prop="company_address">
                  <el-input v-model="formone.company_address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="p-title">家庭信息信息</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :span="8">
                <el-form-item label="家庭住址" class="elformitem" prop="family_address">
                  <el-input v-model="formone.family_address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话" class="elformitem" prop="family_tel">
                  <el-input v-model="formone.family_tel" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="p-title">申请理由</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :span="8">
                <el-form-item label="申请理由" class="elformitem" prop="apply_reason">
                  <el-input class="elinput" type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入内容" v-model="formone.apply_reason">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="h6-contain">
        <el-button class="bt1" @click="cancel">取消</el-button>
        <el-button class="bt" @click="submit('formone')">提交</el-button>
      </div>
    </div>

    <!-- 地图组件 -->
    <el-dialog title="选择签到地址" :visible.sync="dialogVisible" width="50%">
      <BMap ref="bmap_info" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectBmap">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getinternshipregistrationinfo, TemplateFile } from "@/api/practiceplanfreelyapplyinfo";
import { addinternshipregistration } from "@/api/practiceplanfreelyapplyinfo";
import BMap from "@/components/baidu_map.vue";
import { get_report_deatil } from '@/api/practicestudentreport.js'
import UploadFiles from "@/components/upload-files";
export default {
  name: "mainpage",
  components: {
    UploadFiles,
    BMap,
  },
  data() {
    return {
      formone: {
        id: '',
        practice_plan_id: '',
        company: "",
        job: "",
        timearr: [],
        start_date: "",
        end_date: "",
        mode: "",
        address: "",
        sign_in_address: "",
        lng: "",
        lat: "",
        salary: "",
        receiving_letter: "",
        safety_letter: "",
        company_user: "",
        company_tel: "",
        company_address: "",
        family_address: "",
        family_tel: "",
        apply_reason: "",
      },
      dialogVisible: false,
      optiontwo: [
        {
          value: 0,
          label: "其他",
        },
        {
          value: 1,
          label: "参观",
        },
        {
          value: 2,
          label: "顶岗",
        },
        {
          value: 3,
          label: "跟岗",
        },
      ],
      mapicon: require("@/assets/more.png"),
      fileList: [],
      item_desc: {},
      rules: {
        company: [
          { required: true, message: "请填写实习单位", trigger: "blur" },
        ],
        job: [{ required: true, message: "请填写实习岗位", trigger: "blur" }],
        timearr: [
          {
            type: "array",
            required: true,
            message: "请选择实习时间",
            trigger: "change",
          },
        ],
        mode: [
          { required: true, message: "请选择实习方式", trigger: "change" },
        ],
        address: [
          { required: true, message: "请填写实习地点", trigger: "blur" },
        ],
        sign_in_address: [
          { required: true, message: "请填写签到地点", trigger: "blur" },
        ],
        salary: [
          { required: true, message: "请填写实习待遇", trigger: "blur" },
        ],
        company_user: [
          { required: true, message: "请填写单位联系人", trigger: "blur" },
        ],
        company_tel: [
          { required: true, message: "请填写单位电话", trigger: "blur" },
        ],
        company_address: [
          { required: true, message: "请填写单位地址", trigger: "blur" },
        ],
        family_address: [
          { required: true, message: "请填写家庭住址", trigger: "blur" },
        ],
        family_tel: [
          { required: true, message: "请填写联系电话", trigger: "blur" },
        ],
        apply_reason: [
          { required: true, message: "请填写申请理由", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    // 实习报名id，没有是添加，有是修改
    freely_apply_info_id() {
      return this.$route.query.freely_apply_info_id
    },
    // 实习计划id
    plan_id() {
      return this.$route.query.plan_id
    },
    student_id() {
      return this.$route.query.student_id
    },
    type() {
      return this.$route.query.type
    }
  },
  created() {
    this.formone.id = this.freely_apply_info_id;
    this.formone.practice_plan_id = this.plan_id
    if (this.type == '编辑') {
      this.freely_info()
    } else {
      this.init_plan_info()
    }

  },
  methods: {
    // 计划
    init_plan_info() {
      const params = {
        practice_plan_id: this.plan_id,
        student_id: this.student_id
      }
      get_report_deatil(params).then(res => {
        if (res.code == 0) {
          this.item_desc = res.data;
        }
      })
    },
    selectTime(date) {
      if (date && date.length) {
        this.formone.start_date = date[0] / 1000
        this.formone.end_date = date[1] / 1000
      } else {
        this.formone.start_date = ''
        this.formone.end_date = ''
      }
    },
    //报名详情
    freely_info() {
      const params = {
        id: this.freely_apply_info_id,
      }
      getinternshipregistrationinfo(params).then(res => {
        if (res.code == 0) {
          this.item_desc = res.data.user_info;
          const data = res.data.info
          this.formone = {
            id: data.id,
            practice_plan_id: data.practice_plan_id,
            company: data.company,
            job: data.job,
            timearr: [data.start_date * 1000, data.end_date * 1000],
            start_date: data.start_date,
            end_date: data.end_date,
            mode: data.mode,
            address: data.address,
            sign_in_address: data.sign_in_address,
            lng: data.lng,
            lat: data.lat,
            salary: data.salary,
            receiving_letter: data.receiving_letter,
            safety_letter: data.safety_letter,
            company_user: data.company_user,
            company_tel: data.company_tel,
            company_address: data.company_address,
            family_address: data.family_address,
            family_tel: data.family_tel,
            apply_reason: data.apply_reason,
          }
        }
      })
    },

    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    // 地图
    selectBmap() {
      const BmapInfo = this.$refs.bmap_info.selectAddress
      this.formone.lng = BmapInfo.lng;
      this.formone.lat = BmapInfo.lat;
      this.formone.sign_in_address = BmapInfo.address;
      this.dialogVisible = false
    },

    cancel() {
      if (this.type == "编辑") {
        this.$router.push({
          path: "/home/personalcenter/editorregistrationname",
          query: {
            freely_apply_info_id: this.freely_apply_info_id,
          },
        });
      } else {
        this.toback();
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addinternshipregistration()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    checkbt() {
      this.dialogVisible = true
    },
    addinternshipregistration() {
      addinternshipregistration(this.formone)
        .then((response) => {
          if (response.code === 0) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.toback();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUrl1(src) {
      const Url = JSON.parse(src);
      if (Url.length > 0) {
        this.formone.receiving_letter = Url[0].url;
      }
    },
    getUrl2(src) {
      const Url = JSON.parse(src);
      if (Url.length > 0) {
        this.formone.safety_letter = Url[0].url;
      }
    },
    download(status) {
      const params = {
        practice_plan_id: this.plan_id
      }
      TemplateFile(params).then(res => {
        if (res.code == 0) {
          if (status == 1) {
            this.local(res.data.receiving_letter, res.data.receiving_letter_name)
          } else if (status == 2) {
            this.local(res.data.safety_letter, res.data.safety_letter_name)
          }
        }
      })
    },
    local(url, name) {
      var aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = url;
      aLink.target = "_blank";
      aLink.setAttribute("download", name);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink); //下载完成移除元素
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 60px;
    padding-left: 20px;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #0773fc;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #0773fc;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    border-radius: 4px;
    .h-contain {
      min-height: 100px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;
      .h-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          height: 15px;
          border-right: 4px solid #666666;
        }
        .text {
          margin-left: 5px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .h-title2 {
        margin-top: 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .h-title3 {
        margin-top: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
        .h-title3_1 {
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .h2-contain {
      margin-top: 20px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 0 20px;
      .h2-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          height: 15px;
          border-right: 4px solid #666666;
        }
        .text {
          margin-left: 5px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .bodycontain {
        .elform {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 40px;
          .elformitem {
            margin-right: 40px;
            width: 400px;
            .file-select-contain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .file-selecttip {
                padding-left: 10px;
                text-align: left;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                font-weight: 400;
                color: #0773fc;
                cursor: pointer;
              }
              .file-title {
                margin-right: 10px;
                width: 53%;
                border: 1px solid #e4e7ed;
                height: 40px;
                overflow: hidden;
                border-radius: 4px;
                color: #ccc;
                padding-left: 10px;
              }
            }
          }
          .elformitem2 {
            margin-right: 10px;
            width: 180px;
          }
          .elformitem3 {
            margin-right: 40px;
            width: 400px;
            .map {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .elimage {
                margin-left: 10px;
                widows: 30px;
                height: 30px;
                cursor: pointer;
              }
            }
          }
        }
        .p-title {
          font-size: 18px;
          font-weight: bold;
        }
        .el-row-box {
          width: 100%;
          padding: 0 5%;
        }
      }
    }

    .h6-contain {
      margin-top: 20px;
      height: 80px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        background: #0773fc;
        color: #ffffff;
      }
      .bt1 {
        background: #ffffff;
        color: #0773fc;
      }
    }
  }
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
::v-deep .el-dialog__footer {
  border-top: 1px solid #ccc;
}
</style>
